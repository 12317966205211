export default class TimerUtils {
  static async wait(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, milliseconds)
    })
  }

  /**
   * Waits for a condition to be true within a maximum number of attempts.
   * Throws an error if the condition is not met within the allowed time.
   * @param condition Function that evaluates the desired condition
   * @param maxAttempts Maximum number of attempts
   * @param delay Delay between attempts in milliseconds (default: 500ms)
   */
  static async waitUntil(condition: () => boolean, maxAttempts: number, delay: number = 500): Promise<boolean> {
    for (let currentAttempt = 0; currentAttempt < maxAttempts; currentAttempt++) {
      if (condition()) {
        return true // Condition is met, exit successfully
      }
      if (currentAttempt < maxAttempts - 1) {
        await TimerUtils.wait(delay)
      }
    }
    return false
  }

  static formatElapsedTime = function (milliseconds: number): string {
    const seconds = Math.round(milliseconds / 1000)
    let intervalString

    const SECONDS_IN_MINUTE = 60
    const MAX_SECONDS_TO_DISPLAY_AS_SECONDS = 120

    if (seconds < MAX_SECONDS_TO_DISPLAY_AS_SECONDS) {
      intervalString = `${seconds} sec`
    } else {
      const minutes = Math.round(seconds / SECONDS_IN_MINUTE)
      intervalString = `${minutes} min`
    }
    return intervalString
  }

  static computeAndFormatElapsedTime = function (startAt: Date) {
    const finishedAt = new Date()
    const milliseconds = finishedAt.getTime() - startAt.getTime()
    return TimerUtils.formatElapsedTime(milliseconds)
  }
}

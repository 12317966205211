import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { TImageFolder, TUserImage } from '@/types/images'
import type { TDraft } from '@/types/drafts'
import type { TProject, TProjectsSection } from '@/types/projects'
import type { TLandingPage } from '@/types/landing-pages'

export const useCollectionBarStore = defineStore('images-collections-floating-bar', store<TImageFolder>)
export const useDraftsBarStore = defineStore('drafts-floating-bar', store<TDraft>)
export const useFolderBarStore = defineStore('projects-folders-floating-bar', store<TProjectsSection>)
export const useImagesBarStore = defineStore('images-floating-bar', store<TUserImage>)
export const useProjectsBarStore = defineStore('projects-floating-bar', store<TProject>)
export const useLandingPagesBarStore = defineStore('landing-pages-floating-bar', store<TLandingPage>)

function store<T extends { id: number }>() {
  const list = ref<T[]>([])

  function add(item: T) {
    list.value.push(<any>item)
  }

  function remove(item: T) {
    const index = list.value.findIndex((p) => p.id === item.id)
    if (index !== -1) {
      list.value.splice(index, 1)
    }
  }

  function toggle(item: T) {
    is(item) ? remove(item) : add(item)
  }

  function clear() {
    list.value.length = 0
  }

  function is(item: T) {
    return list.value.some((e) => e.id === item.id)
  }

  return { toggle, clear, is, list }
}

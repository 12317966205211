import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import type { TProjectAudiobook } from '@/types/audiobooks'
import type { TDraft } from '@/types/drafts'
import type { TProjectFlipbook } from '@/types/flipbooks'
import type { TProject, TProjectsSection } from '@/types/projects'
import type { TLandingPage } from '@/types/landing-pages'

export const useAudiobooksMenuStore = defineStore('audiobooks-menu', store<TProjectAudiobook>)
export const useDraftsMenuStore = defineStore('drafts-menu', store<TDraft>)
export const useFlipbooksMenuStore = defineStore('flipbooks-menu', store<TProjectFlipbook>)
export const useFoldersMenuStore = defineStore('folders-menu', store<TProjectsSection>)
export const useProjectsMenuStore = defineStore('projects-menu', store<TProject>)
export const useLandingPagesMenuStore = defineStore('landing-pages-menu', store<TLandingPage>)

function store<T extends { id: number }>() {
  const user = useUserStore()
  const item = ref<T | null>(null)
  const position = ref<{ top: number; left: number }>({ top: 0, left: 0 })
  const flip = ref<boolean>(false)
  const opened = ref<boolean>(false)
  let handler: ReturnType<typeof setTimeout> | null = null

  function open(chosenItem: T, referer: HTMLElement) {
    user.ping()

    handler && clearTimeout(handler)
    if (chosenItem.id === item.value?.id && opened.value) {
      close()
      return
    }

    const box = referer.getBoundingClientRect()

    flip.value = box.left + box.width + 100 > window.innerWidth
    item.value = <any>chosenItem
    opened.value = true
    position.value = {
      top: box.y + window.scrollY,
      left: box.x + (flip.value ? 0 : box.width) + window.scrollX,
    }
  }

  function close() {
    handler && clearTimeout(handler)
    handler = setTimeout(() => (opened.value = false))
  }

  return { open, close, item, position, flip, opened }
}

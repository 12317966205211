import TimerUtils from '@/helpers/timerUtils'
import type { TDraftImportType } from '@/types/drafts'
import { DropboxUrlUtil } from '@/helpers/dropboxUrlUtil'
import {
  ERemoteImportURLStatus,
  ERemoteImportURLType,
  TRemoteImportURL,
  type TUploadedFileInfo,
} from '@/types/remote-imports'
import { track } from '@/helpers/mixpanelDes'
import { ImportTypes } from '@/helpers/importTypes'
import { ReadabilityWithIframe } from '@/helpers/readabilityWithIframe'
import { defineStore } from 'pinia'
import { http } from '@/helpers/http'
import { ref, reactive } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useDraftsStore } from '@/stores/drafts'
import { useSnackbarStore } from '@/stores/snackbar'
import { useFlipbooksStore } from '@/stores/flipbooks'
import { useModalStore } from '@/stores/modal'
import { GDocImport } from '@/helpers/gdocImport'
import { useRouter } from 'vue-router'
import { useStorage } from '@vueuse/core'

export const useRemoteImportStore = defineStore('remote-imports', () => {
  const urls = ref<TRemoteImportURL[]>([])
  const importType = ref<ERemoteImportURLType | null>(null)
  const draftId = ref<number>(-1)
  const draftTitle = ref<string>('New project ' + Math.floor(Date.now() / 1000))
  const content = ref<string>('')
  const drafts = useDraftsStore()
  const flipbooks = useFlipbooksStore()
  const modal = useModalStore()
  const snack = useSnackbarStore()
  const cookies = useCookies()
  const router = useRouter()
  const uploadedFileName = ref<string>('')
  const uploadedFileLink = ref<string>('')
  const pdfImportIntoFlipbookOnly = ref<boolean>(false)
  const processingMessage = ref<string>('Converting ...')
  const docsTab = useStorage<string | null>('docsTab', null)
  let progressInterval: NodeJS.Timeout
  let importErrorId = ''
  const dndUrl = ref<string | null>(null)

  router.beforeEach(() => snack.remove(importErrorId))

  function isFileImportType(type?: ERemoteImportURLType): boolean {
    return (
      type === ERemoteImportURLType.DocX ||
      type === ERemoteImportURLType.Flipbook ||
      type === ERemoteImportURLType.Audio ||
      type === ERemoteImportURLType.Pdf ||
      type === ERemoteImportURLType.YouTube ||
      type === ERemoteImportURLType.Video ||
      type === ERemoteImportURLType.GDoc
    )
  }

  function getTitleFromContent(content: string, defaultTitle: string = '(new import)'): string {
    const elementsPriority: string[] = ['h1', 'h2']
    let title: string | null = null

    const contentLower = content.toLowerCase()
    for (const element of elementsPriority) {
      const openTagStart = `<${element}`
      const closeTag = `</${element}>`
      const startIndex = contentLower.indexOf(openTagStart)
      if (startIndex >= 0) {
        const startTagEnd = content.indexOf('>', startIndex)
        const endIndex = content.indexOf(closeTag, startTagEnd)
        if (endIndex > startTagEnd) {
          title = content.substring(startTagEnd + 1, endIndex)
          break
        }
      }
    }
    if (title) {
      return title.trim()
    }
    return defaultTitle
  }

  function init(type: ERemoteImportURLType) {
    importType.value = type
  }

  function addUrl(url: TRemoteImportURL) {
    if (importType.value !== null) {
      const reactiveUrl = reactive(url)
      urls.value.push(reactiveUrl)
      fetchUrl(reactiveUrl)
    } else {
      throw new Error(`Import type not initialized`)
    }
  }

  function removeUrlOrFile(index: number) {
    urls.value.splice(index, 1)
  }

  /*
    function addFiles(files: File[] | null) {
      if (importType.value !== null && files && files.length > 0) {
        files.forEach((f) => importType.value && urls.value.push(new TRemoteImportURL(importType.value, '', f)))
      } else {
        throw new Error(`Import type not initialized`)
      }
    }
    */

  async function fetchFromWeb(url: TRemoteImportURL) {
    const gdoc = new GDocImport()
    const isGdoc = gdoc.isGDocUrl(url.url)
    const trackEventName: string = isGdoc ? 'imported-google-document' : 'imported-article'
    const trackErrorEventName: string = isGdoc ? 'error-importing-google-document' : 'imported-article-failed'
    try {
      url.status = ERemoteImportURLStatus.Fetching
      url.progress = 0
      const resource = new ReadabilityWithIframe()
      const result = await resource.doReadabilityFromUrl(url.url)
      url.content = result.data.content
      url.title = result.data.title

      if (url.content.startsWith('%PDF-')) {
        track(trackErrorEventName, {
          url: url.url,
          info: 'this was a pdf file not a blog',
        })
        url.status = ERemoteImportURLStatus.Error
        if (dndUrl.value) {
          track('project-creating-dnd-failed', {
            url: url.url,
            info: 'this was a pdf file not a blog',
          })
        }
        snack.add('Error, pdf file detected. Please use import from pdf feature.')
        return
      }
      if (isGdoc) {
        // use readability as fallback
        const result = await gdoc.getHtmlFromGDocValidUrl(url.url, window.location.origin)
        if (result.html) {
          url.content = result.html
        } else {
          snack.add(result.message ?? 'Error when fetching GDoc')
          url.status = ERemoteImportURLStatus.Error
          return
        }
      }

      url.status = ERemoteImportURLStatus.Fetched
      url.progress = 100

      if (url.content) {
        content.value = content.value.concat(url.content)
      }
      try {
        const title = result.data.title ? result.data.title : getTitleFromContent(result.data.content)
        draftTitle.value = title
        draftId.value = await drafts.save(
          draftId.value >= 0 ? draftId.value : null,
          title,
          content.value,
          result.data.url ?? '',
          resolveImportType(url),
        )
        track(trackEventName, {
          url: url.url,
        })
        if (dndUrl.value) {
          track('project-creating-dnd', {
            url: url.url,
          })
        }
        snack.add('Saved to doc')
      } catch {
        snack.add('Error when saving to doc')
      }
    } catch {
      track(trackErrorEventName, {
        url: url.url,
      })
      url.status = ERemoteImportURLStatus.Error
      if (dndUrl.value) {
        track('project-creating-dnd-failed', {
          url: url.url,
        })
      }
      snack.add('Error reading from URL')
    }
  }

  async function fetchFromUrl(url: TRemoteImportURL) {
    url.url = url.url.replace(/ /g, '%20')
    const dBox = new DropboxUrlUtil()
    if (dBox.isDropboxShareUrl(url.url)) {
      url.url = dBox.getDirectDownloadUrl(url.url)
    }
    if (!ImportTypes.urlValidate(url.url)) {
      snack.add('Wrong URL')
      return false
    }
    await importFromUrl(url)
  }

  function getProgressUrlForType(type: ERemoteImportURLType) {
    const tmp = new Date()
    const sesId = cookies.get('PHPSESSID')
    const urls = {
      [ERemoteImportURLType.Pdf]: '/assets/tmpPdfUpload/pdfProgress_' + sesId + '.json',
      [ERemoteImportURLType.DocX]: '/assets/tmpPdfUpload/docxProgress_' + sesId + '.json',
      [ERemoteImportURLType.Flipbook]: '/assets/tmpPdfUpload/pdfProgress_' + sesId + '.json',
      [ERemoteImportURLType.Audio]: '',
      [ERemoteImportURLType.Video]: '',
      [ERemoteImportURLType.YouTube]: '',
      [ERemoteImportURLType.Web]: '',
      [ERemoteImportURLType.GDoc]: '',
    }
    let url = urls[type]
    if (!url) {
      throw new Error('Error in getDownloadProgress. unknown type ' + type)
    }
    url = url + '?rand=' + tmp.getTime()
    return url
  }

  async function importFromUrl(url: TRemoteImportURL) {
    const filename = url.url.substring(url.url.lastIndexOf('/') + 1).split('.')
    filename.pop()
    try {
      await importFile(false, filename.join('.'), url)
      if (dndUrl.value) {
        track('project-creating-dnd', {
          url: url.url,
        })
      }
    } catch (e) {
      if (dndUrl.value) {
        track('project-creating-dnd-failed', {
          url: url.url,
        })
      }
      snack.add(e instanceof Error ? e.message : 'Error')
    }
  }

  async function getDownloadProgress(url: TRemoteImportURL) {
    try {
      const res = await http.get<{
        total: number
        current: number
      }>(getProgressUrlForType(url.type))

      if (!res) {
        clearInterval(progressInterval)
        return
      }

      if (res.total === res.current && res.total > 0) {
        url.progress = 100
        clearInterval(progressInterval)
      } else {
        if (res.total > 0) {
          url.progress = Math.round((100 / res.total) * res.current)
        }
        return
      }
    } catch (e) {
      clearInterval(progressInterval)
      if (dndUrl.value) {
        track('project-creating-dnd-failed', {
          url: url.url,
        })
      }
      console.log(e)
      url.status = ERemoteImportURLStatus.Error
      throw new Error('Error while getting download progress')
    }
  }

  function resolveImportType(url: TRemoteImportURL): TDraftImportType | null {
    if (url.type === ERemoteImportURLType.DocX) {
      return { type: 'from-docx', subType: 'docx', newDashboard: true }
    }
    if (url.type === ERemoteImportURLType.Pdf) {
      return { type: 'from-pdf', subType: 'pdf', newDashboard: true }
    }
    if (url.type === ERemoteImportURLType.GDoc) {
      return { type: 'from-gdoc', subType: 'gdoc', newDashboard: true }
    }
    if (url.type === ERemoteImportURLType.Web) {
      return { type: 'from-blog' }
    }
    return null
  }

  async function handleAudioOrVideo(url: TRemoteImportURL, realName: string) {
    if (url.url.indexOf('youtube.com') >= 0 || url.url.indexOf('youtu.be') >= 0) {
      url.status = ERemoteImportURLStatus.ReadyToTranscribe
      return
    }
    url.status = ERemoteImportURLStatus.Fetching
    const helper = ImportTypes.helpers[url.type]
    processingMessage.value = 'Fetching...'
    if (!isFileImportType(url.type) || !helper) return
    try {
      const res = await http.post<{
        status: string
        uploadedFileInfo: TUploadedFileInfo
        content: string
        message?: string
      }>(helper.endPoint, {
        audioOrVideoUrl: url.url,
      })
      if (res?.status === 'error') {
        url.status = ERemoteImportURLStatus.Error
        snack.add(res.message ?? 'Error')
        throw new Error(res.message)
      }
      if (!res) {
        throw new Error('Failed to fetch file from URL')
      }
      url.info = res.uploadedFileInfo
      url.status = ERemoteImportURLStatus.ReadyToTranscribe
    } catch (e) {
      if (dndUrl.value) {
        track('project-creating-dnd-failed', {
          url: url.url,
        })
      }
      url.status = ERemoteImportURLStatus.Error
      throw new Error('Failed to fetch file from URL')
    }
  }

  async function importFile(alreadyOnServer: boolean, realName: string, url: TRemoteImportURL) {
    const helper = ImportTypes.helpers[url.type]
    snack.remove(importErrorId)

    processingMessage.value = helper?.processingLoadingMessage ?? 'Converting ...'

    if (['audio', 'video', 'youtube'].includes(importType.value ?? '')) {
      handleAudioOrVideo(url, realName)
      return
    }

    if (!isFileImportType(url.type) || !helper) return
    const data = {
      fileUrl: url.url,
      fileLink: '',
      name: '',
      fileName: url.url ? url.url : '',
      description: '',
    }

    if (alreadyOnServer) {
      data.fileName = realName
      data.fileLink = url.fileLink || ''
    }

    if (url.type == ERemoteImportURLType.Flipbook) {
      data.name = realName
    }

    url.status = ERemoteImportURLStatus.Processing
    processingMessage.value = 'Processing...'

    url.progress = 0
    if (!alreadyOnServer) {
      progressInterval = setInterval(() => getDownloadProgress(url), 2500)
    }
    const startAt = new Date()
    let failed = false
    try {
      const res = await http.post<{
        id?: number
        token?: string
        status: string
        message: string
        content: string
      }>(helper.endPoint, data)

      pdfImportIntoFlipbookOnly.value = false
      const messageStringPresent: boolean =
        typeof res.message === 'string' && typeof res.message.includes === 'function'

      if (
        res?.status === 'error' &&
        messageStringPresent &&
        res.message?.includes('Error importing pdf') &&
        res.message?.includes('too many pages')
      ) {
        pdfImportIntoFlipbookOnly.value = true
      } else if (res?.status === 'error') {
        url.status = ERemoteImportURLStatus.Error
        importErrorId = snack.add(res.message ?? 'Error during import', { permanent: true })
        throw new Error(res.message)
      }
      if (!res) {
        throw new Error('Failed to fetch file from URL')
      }
      url.status = ERemoteImportURLStatus.Fetched
      url.content = res.content
      if (url.content) {
        content.value = content.value.concat(url.content)
      }

      uploadedFileName.value = data?.fileName
      uploadedFileLink.value = data?.fileLink

      if (helper.module === 'flipbook' && res?.id) {
        const flipbookUrl: string = await flipbooks.buildFlipbookUrl(res.id, res?.token ?? '', 'FP')

        await modal.open(() => import('@/components/modal/DesModalFlipbookCreated.vue'), {
          flipbookId: res.id,
          flipbookUrl,
        })
      } else if (helper.module === 'flipbook') {
        snack.add('Error creating flipbook')
      } else if (pdfImportIntoFlipbookOnly.value !== true) {
        // save to draft
        if (!(helper.module === 'flipbook')) {
          try {
            const name = url.file?.name.split('.').slice(0, -1).join('.') ?? realName
            if (!url.title) {
              url.title = name
            }
            draftId.value = await drafts.save(null, name, res.content, url.url, resolveImportType(url))
            draftTitle.value = url.file?.name.split('.').slice(0, -1).join('.') ?? realName
            snack.add('Saved to doc')
          } catch (e) {
            snack.add('Error when saving to doc')
          }
        }
      }
    } catch (e) {
      failed = true
      url.status = ERemoteImportURLStatus.Error
      if (dndUrl.value) {
        track('project-creating-dnd-failed', {
          url: url.url,
        })
      }
      throw new Error('Failed to fetch file from URL')
    } finally {
      const intervalString = TimerUtils.computeAndFormatElapsedTime(startAt)
      const trackEventName = failed ? helper.trackErrorMsg : helper.trackSuccessTag
      track(trackEventName, {
        time: intervalString,
        ulr: url.url ?? '',
      })
      /*
            snack.add("todo - handle ui for fetched")
            */
    }
  }

  async function fetchUrl(url: TRemoteImportURL) {
    if (
      url.status === ERemoteImportURLStatus.Fetching ||
      url.status === ERemoteImportURLStatus.Fetched ||
      url.url.length === 0
    ) {
      return
    }
    if (url.type === ERemoteImportURLType.Web || url.type === ERemoteImportURLType.GDoc) {
      await fetchFromWeb(url)
    } else if (isFileImportType(url.type)) {
      await fetchFromUrl(url)
    } else if ([ERemoteImportURLType.Video, ERemoteImportURLType.Audio].includes(url.type)) {
      await handleAudioOrVideo(url, url.file?.name ?? url.url)
    } else {
      console.log('Unknown URL import type')
    }
  }

  async function importVideoFromYouTube(url: TRemoteImportURL, language: string, title?: string) {
    snack.add('Converting file: This may take a few minutes depending on audio/video length')
    url.status = ERemoteImportURLStatus.Fetching

    url.url = url.url.replace('https://youtu.be/', 'https://www.youtube.com/watch?v=')
    try {
      const res = await http.post<{
        status: string
        message: string
      }>('/scheduleYoutubeImport', {
        url: url.url,
        ...(title ? { title } : {}),
        language,
      })
      if (res?.status === 'error') {
        track('error-scheduling-audio-video', {
          title,
          url,
        })
        url.status = ERemoteImportURLStatus.Error
        snack.add(res.message)
        throw new Error(res.message)
      }
      if (!res) {
        track('error-scheduling-audio-video', {
          title,
          url,
        })
        snack.add('Failed to queue video for transcription')
        throw new Error('Failed to queue video for transcription')
      }
      track('scheduled-audio-video-transcription', {
        title,
        url,
      })
      docsTab.value = 'transcriptions'
      router.push('/docs/transcriptions')
      snack.add(
        "Your video has been queued for transcription.  Please check your email in a few minutes and we’ll notify you when it's ready.",
      )
    } catch (e) {
      url.status = ERemoteImportURLStatus.Error
      snack.add('Failed to queue video for transcription')
      throw new Error('Failed to queue video for transcription')
    }
  }

  async function startTranscription(url: TRemoteImportURL, language: string = 'en') {
    if (url.url.indexOf('youtube.com') >= 0 || url.url.indexOf('youtu.be') >= 0) {
      importVideoFromYouTube(url, url.language ?? language)
      return
    }
    try {
      const res = await http.post<{
        status: string
        message: string
        content: string
      }>('/recognize_speech_temi', {
        draftName: url.info?.fileName ?? '',
        fileLink: url.info?.desTmpUrl ?? '',
        fileName: url.info?.fileName ?? '',
        importedFromUrl: url.info?.importedFromUrl ?? '',
        language: url.language ?? language,
        tmpFilePath: url.info?.desTmpFullPath ?? '',
        uniqueTimestamp: new Date().valueOf(),
      })
      if (res?.status === 'error') {
        url.status = ERemoteImportURLStatus.Error
        throw new Error(res.message)
      }
      if (!res) {
        throw new Error('Failed to schedule transcription')
      }
      snack.add('Transcription scheduled')
      docsTab.value = 'transcriptions'
      router.push('/docs')
    } catch (e) {
      if (dndUrl.value) {
        track('project-creating-dnd-failed', {
          url: url.url,
        })
      }
      url.status = ERemoteImportURLStatus.Error
      throw new Error('Failed to fetch file from URL')
    }
  }

  async function setNewProjectData(draftIdParam: number | null, draftTitleParam: string, contentParam: string) {
    draftId.value = draftIdParam ?? -1
    draftTitle.value = draftTitleParam
    content.value = contentParam
    await http.post('/projects/import_proxy', {
      title: draftTitleParam,
      content: contentParam,
    })
  }

  return {
    content,
    urls,
    init,
    addUrl,
    uploadedFileName,
    uploadedFileLink,
    // addFiles,
    draftId,
    draftTitle,
    importFile,
    dndUrl,
    removeUrlOrFile,
    fetchUrl,
    isFileImportType,
    startTranscription,
    setNewProjectData,
    pdfImportIntoFlipbookOnly,
    processingMessage,
  }
})

import { http } from '@/helpers/http'
import { defineStore } from 'pinia'
import type { TLandingPage, TProjectMeta } from '@/types/landing-pages'
import { ref } from 'vue'

export const useLandingPagesStore = defineStore('landing-pages', () => {
  const landingPages = ref<TLandingPage[]>([])
  const projectId = ref<number | null>(null)

  async function fetch() {
    const res = await http.get<{
      results: TLandingPage[]
    }>(`/landing-pages`)

    landingPages.value = res?.results ?? []
  }

  async function getProjectMeta(id: number) {
    const res = await http.get<TProjectMeta>(`/landing-pages/project/${id}`)

    return res ?? null
  }

  async function rename(id: number, newName: string): Promise<boolean> {
    console.log('Rename landing page...', id, newName)
    return true
  }

  async function remove(lp: TLandingPage): Promise<boolean> {
    console.log('Remove landing page...', lp)
    return true
  }

  async function removeMany(lps: TLandingPage[]): Promise<boolean> {
    console.log('Remove landing pages...', lps)
    return true
  }

  return {
    landingPages,
    projectId,
    fetch,
    getProjectMeta,
    rename,
    remove,
    removeMany,
  }
})

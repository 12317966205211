import { ref } from 'vue'
import { defineStore } from 'pinia'
import { http } from '@/helpers/http'
import { useUserStore } from '@/stores/user'
import type { TAgency, TGetAgency } from '@/types/agency'

export const useAgencyStore = defineStore('agency', () => {
  const user = useUserStore()
  const agency = ref<TAgency>()

  async function getAgency (): Promise<void> {
    const result: TGetAgency = await http.get<TGetAgency>('/agency')
    if (result?.agency) {
      agency.value = result.agency
    }
  }

  async function generateSlugFromName (name: string, id: number): Promise<string> {
    return await http.post('/agency/slug', { name, id }, true)
  }

  async function update (data: TAgency): Promise<{result: boolean}> {
    return http.post<{result: boolean}>('/agency', data)
  }

  function canManageUsers() {
    return user.accountType.total_users > 0 && user.user.permissions['users.update'] === 1
  }

  function canManageClients() {
    return user.accountType.agency_clients > 0
  }

  function canEditTemplates() {
    return user?.user?.permissions['templates.editor'] === 1 || user?.user?.permissions['superuser'] === 1
  }

  function canManageSettings() {
    return canManageClients() && !user.user.parent_account_id
  }

  return {
    agency,
    getAgency,
    update,
    generateSlugFromName,
    canManageUsers,
    canManageClients,
    canManageSettings,
    canEditTemplates,
  }
})

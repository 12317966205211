import { defineStore } from 'pinia'
import { ref } from 'vue'
import { http } from '@/helpers/http'
import { useUserStore } from '@/stores/user'
import { useProjectsStore } from '@/stores/projects'
import { Products } from '@/helpers/product'
import type { TDropdownOption } from '@/components/form/DesDropdown.vue'
import type {
  TTemplateList,
  TPageSize,
  TTemplate,
  TTemplateType,
  TTemplateTypes,
  TTemplateThemeList,
  TTemplateTypeList,
  TResponse,
} from '@/types/templates'
import type { TProject, TProjectCreateResult } from '@/types/projects'

export const useTemplatesStore = defineStore('templates', () => {
  const templateList = ref<TTemplateList>([])
  const proTemplateList = ref<TTemplateList>([])
  const proTemplateLabel: string = 'PRO_TEMPLATE'
  const users = useUserStore()
  const projects = useProjectsStore()
  const templateToReview = ref<TTemplate | null>(null)

  const pageOrientations: TDropdownOption[] = [
    {
      name: 'Portrait',
      label: 'Portrait',
    },
    {
      name: 'Landscape',
      label: 'Landscape',
    },
  ]
  const allPageSizes: TPageSize[] = [
    { name: 'Letter', width: 816, height: 1056, landscape: false },
    { name: 'A4', width: 794, height: 1123, landscape: false },
    { name: 'A5', width: 558, height: 794, landscape: false },
    { name: '6x9', width: 576, height: 864, landscape: false },
    { name: 'Legal', width: 816, height: 1344, landscape: false },
    { name: 'A3', width: 1124, height: 1588, landscape: false },
    { name: 'Square', width: 1080, height: 1080, landscape: false },
  ]

  function assignSortValue(
    templateType: string,
    defaultProTemplate: number,
    matchTheme: boolean,
    proFirst?: boolean,
  ): number {
    if (proFirst && defaultProTemplate === 1) {
      return -4
    } else if (matchTheme) {
      return -3
    } else if (defaultProTemplate === 1) {
      return -2
    } else if (templateType === proTemplateLabel) {
      return -1
    }

    return 1
  }

  function sortTemplates(templates: TTemplateList, themes: TDropdownOption[], proFirst?: boolean): TTemplateList {
    const themeIds: number[] = themes.map((e) => Number(e.name))
    return templates.sort((a, b) => {
      const includesThemeA: boolean = themes.length ? a.tags.some((tag: number) => themeIds.includes(tag)) : false
      const includesThemeB: boolean = themes.length ? b.tags.some((tag: number) => themeIds.includes(tag)) : false
      const typeA: number = assignSortValue(a.template_type, a.default_pro_template, includesThemeA, proFirst)
      const typeB: number = assignSortValue(b.template_type, b.default_pro_template, includesThemeB, proFirst)

      return typeA - typeB
    })
  }

  function typesObjectToArray(types: TTemplateTypes): TTemplateTypeList {
    return Object.entries(types).map(([key, value]) => {
      const toReturn: TTemplateType = {
        label: key,
        name: value,
      }
      return toReturn
    })
  }

  function filterTemplateList(
    themeOption: TDropdownOption[],
    searchString: string,
    typeOption?: TDropdownOption,
    filterPro?: boolean,
  ): TTemplateList {
    const listToFilter = filterPro ? proTemplateList.value : templateList.value
    const filteredTemplateList = listToFilter
      .filter((template) => template.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
      .filter((template) => {
        const name: string | undefined = typeOption?.name
        const noFilterSet: boolean = !name || name === 'All'
        const filterNameMatch: boolean = template?.template_type?.toLocaleLowerCase() === name?.toLocaleLowerCase()
        const filterLabelMath: boolean =
          template?.template_type?.toLocaleLowerCase() === typeOption?.label?.toLocaleLowerCase()
        return noFilterSet || filterNameMatch || filterLabelMath
      })

    return sortTemplates(filteredTemplateList, themeOption, filterPro)
  }

  async function initTemplateList() {
    let endpoint: string = ''
    if (users.user.account_type === Products.Standard) {
      endpoint = '/pr-templates/0'
      const proTemplatesDB: TTemplateList | null = await http.get<TTemplateList>('/pr-templates/1')
      proTemplateList.value = proTemplatesDB ?? []
    } else {
      endpoint = '/pr-templates/0/1'
    }

    const templatesDB: TTemplateList | null = await http.get<TTemplateList>(endpoint)
    templateList.value = templatesDB ?? []
  }

  async function getTemplateThemes(): Promise<TTemplateThemeList> {
    const themes: TTemplateThemeList | null = await http.post<TTemplateThemeList>('/tags/hints', {})
    return themes ?? []
  }

  async function deleteTemplate(template: TTemplate): Promise<void> {
    await http.delete('/pr-templates/' + template.id, true)
    const index = templateList.value.indexOf(template)
    if (index !== -1) {
      templateList.value.splice(index, 1)
    }
  }

  async function getTemplateTypes(): Promise<TTemplateTypeList> {
    const types: TTemplateTypes | null = await http.post<TTemplateTypes>('/get-template-types/')
    const toReturn: TTemplateTypeList = types ? typesObjectToArray(types) : []
    toReturn.unshift({ label: 'All', name: 'All' })
    return toReturn
  }

  function templatesByTags(tags: number[], excludeIds: number[]): TTemplate[] {
    return templateList.value
      .filter((template) => tags.some((tag) => template.tags.includes(tag)))
      .filter((template) => !excludeIds.includes(template.id))
  }

  async function updatePublishedTemplate(projectId: number): Promise<TResponse> {
    const result: TResponse | null = await http.post<TResponse>('/update_published_template/', { projectId })
    if (!result || result?.status !== 'success') {
      return {
        status: 'error',
        message: result?.message ?? 'Error updating template',
      }
    }

    return result
  }

  async function unpublishTemplate(projectId: number): Promise<TResponse> {
    const result: TResponse | null = await http.post<TResponse>('/unpublish_template/', { projectId })
    if (!result || result?.status !== 'success') {
      return {
        status: 'error',
        message: result?.message ?? 'Error unpublishing template',
      }
    }

    return result
  }

  async function duplicateTemplate(oldTemplateName: string, newTemplateName: string, templateId: number, userReceiverEmail?: string,userReceiverId?: number): Promise<TResponse> {
    const result: TResponse | null = await http.post<TResponse>('/duplicate_template/', { oldTemplateName, newTemplateName, templateId, userReceiverEmail: userReceiverEmail ?? '', userReceiverId: userReceiverId ?? 0 })
    if (!result || result?.status !== 'success') {
      return {
        status: 'error',
        message: result?.message ?? 'Error duplicating template',
      }
    }

    return result
  }

  async function publishTemplate(projectId: number): Promise<TResponse> {
    const result: TResponse | null = await http.post<TResponse>('/publish_template/', { projectId })
    if (!result || result?.status !== 'success') {
      return {
        status: 'error',
        message: result?.message ?? 'Error publishing template',
      }
    }

    return result
  }

  async function createNewTemplate(): Promise<boolean> {
    const template: TTemplate | null = await http.get('/pr-default-template-record/')
    if (!template) {
      return false
    }

    const projectName = 'Template ' + Math.round(new Date().getTime() / 1000)
    const payload = {
      name: projectName,
      type: 'ebook',
      template: template.id,
      templateName: template.name,
      pageSize: "A4",
      pageWidth: 816,
      pageHeight: 1056,
      templateType: true
    }

    const projectCreated: TProjectCreateResult | null = await http.post('/projects', payload)
    if (!projectCreated) {
      return false
    }

    const project: TProject | null = await http.get('/projects/' + projectCreated.id)
    if (!project) {
      return false
    }

    const payloadT = {
      name: projectName,
      projectId: project.id,
      thumbnailLink: 'assets/images/projects/' + project.cover,
      html: project?.pages && project.pages[0] && project.pages[0].html ? project?.pages[0]?.html : '',
      css: project?.pages && project.pages[0] && project.pages[0].css ? project?.pages[0]?.css : ''
    }

    const newTemplate: TTemplate | null = await http.post('/templates-editor/template', payloadT)
    if (!newTemplate) {
      return false
    }

    await http.post('/projects/import_proxy', {
      title: 'New Template',
      content: 'new_template',
    })

    projects.goToBuilderAndEdit(project.slug_id, project.slug)
    return true
  }

  return {
    pageOrientations,
    publishTemplate,
    duplicateTemplate,
    updatePublishedTemplate,
    unpublishTemplate,
    allPageSizes,
    templateList,
    proTemplateLabel,
    templateToReview,
    createNewTemplate,
    filterTemplateList,
    initTemplateList,
    getTemplateThemes,
    getTemplateTypes,
    templatesByTags,
    deleteTemplate,
  }
})
